window.initMap = function () {
    window.map = new google.maps.Map(document.getElementById("map"), {
        center: {lat: 53.8, lng: -8.0},
        fullscreenControl: false,
        mapTypeControl: false,
        minZoom: 5,
        streetViewControl: false,
        zoomControl: true,
        zoom: 6,
        clickableIcons: false,
    });
};

$('#viewMap').on('hidden.bs.modal', function () {
    if (window.clusterer) {
        window.clusterer.clearMarkers();
    }
    window.clusterer = null;
});

$('#viewMap').on('shown.bs.modal', function () {
    if (window.clusterer) {
        window.clusterer.clearMarkers();
    }
    const infoWindow = new google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
    });
    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // Create markers.
    const markers = mapLocations.map((position, i) => {
        const label = labels[i % labels.length];
        const map_label = mapLabels[i];
        const marker = new google.maps.Marker({
            position,
            label,
        });

        // markers can only be keyboard focusable when they have click listeners
        // open info window when marker is clicked
        marker.addListener("click", () => {
            infoWindow.setContent(map_label);
            infoWindow.open(map, marker);
        });

        return marker;
    });

    // Add a marker clusterer to manage the markers.
    window.clusterer = new markerClusterer.MarkerClusterer({ markers, map });

    // set map bounds
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
    }

    // set center point and fit map to bounds
    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds, 1);

    // set zoom level if only one marker
    if (markers.length === 1) {
        map.setZoom(18);
    }
});
